<template>
  <div id="app">
    <van-image
      width="100%"
      height="150"
      fit="cover"
      src="https://elite-mini-program.oss-cn-hangzhou.aliyuncs.com/dingtalk-file/bg-wide.png"
    />
    <!-- <van-tabbar v-model="active" route>
      <van-tabbar-item icon="star-o" replace to="/">评价</van-tabbar-item>
      <van-tabbar-item icon="setting-o" replace to="/settings"
        >设置</van-tabbar-item
      >
    </van-tabbar> -->
    <div class="position" @click="clearToken"></div>
    <router-view />
  </div>
</template>

<script>
import Vue from "vue";
import { Tabbar, TabbarItem, Image as VanImage } from "vant";
import { removeToken } from "./utils/auth";

Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(VanImage);

export default {
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    clearToken() {
      removeToken();
    },
  },
};
</script>


<style lang="scss" scoped>
@import "colorui/main.css";
@import "colorui/icon.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

.position {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.screen {
  max-width: 600px;
  margin: auto;
}
</style>
