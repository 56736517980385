const rootUrl = "https://ddserver.elite-audio.com:456/admin/";
const root = "https://ddserver.elite-audio.com:456";
// const root = "http://127.0.0.1:8999";
// const rootUrl = "http://127.0.0.1:8999/admin";
const corpId = "ding2ed665dfe133e896";
const agentId = "3150188546";
const api = {
  rootUrl: rootUrl,
  root: root,
  corpId: corpId,
  agentId: agentId,
};
export default api;
