import request from "@/utils/request";

export function updateDepartmentsAction(data) {
  return request({
    url: "/getCompanyInfo/updateDepartments",
    method: "post",
    data,
  });
}

export function getPersonListAction(data) {
  return request({
    url: "/getCompanyInfo/getPersonList",
    method: "post",
    data,
  });
}

export function getDepartmentsAction(data) {
  return request({
    url: "/getCompanyInfo/getDepartments",
    method: "post",
    data,
  });
}

export function organizationInfoAction() {
  return request({
    url: "/getCompanyInfo/organizationInfo",
    method: "post",
  });
}







