import request from "@/utils/request";

export function getList() {
  return request({
    url: "/index/getList",
    method: "post",
  });
}


