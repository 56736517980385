import Axios from "axios";
import { getToken } from "../utils/auth"; // get token from cookie
import { Message } from "element-ui";
import { showLoading, hideLoading } from "./loading";
import api from "../api/api";
import router from "../router"; // 引入vue-router的router
// Axios.defaults.withCredentials = false; //让ajax携带cookie
// Axios.defaults.headers.common["Content-Type"] =
//   "application/json;charset=UTF-8";
// "application/x-www-form-urlencoded;charset=UTF-8";

const service = Axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: api.rootUrl, // url = base url + request url
  timeout: 5000000,
});

service.interceptors.request.use(
  (config) => {
    showLoading();
    const hasToken = getToken();
    config.headers["x-admin-token"] = hasToken;
    return config;
  },
  (error) => {
    console.error(error); // for debug
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    hideLoading(); //关闭加载
    const res = response.data;
    const err = res.errno;
    if (err == 120) {
      // 如果token不存在或为undefined，跳转到登录页面
      Message({
        message: "登录已过期,请重新登录",
        type: "error",
        duration: 5 * 1000,
      });
      router.replace({
        path: "/login",
      });
      return Promise.reject(new Error("请登录")); // 这里返回一个rejected的promise，让请求中断
    }

    return res;
  },
  (error) => {
    hideLoading(); //关闭加载
    Message({
      message: error,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
