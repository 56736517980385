import request from "@/utils/request";

// 废弃
export function saveEvaluteUsers(data) {
  return request({
    url: "/evaluation/saveEvaluteUsers",
    method: "post",
    data,
  });
}

export function toggleUserSwitch(data) {
  return request({
    url: "/evaluation/toggleUserSwitch",
    method: "post",
    data,
  });
}

export function deleteInviteUserAction(data) {
  return request({
    url: "/evaluation/deleteInviteUser",
    method: "post",
    data,
  });
}

export function inviteUsersAction(data) {
  return request({
    url: "/evaluation/inviteUsers",
    method: "post",
    data,
  });
}

export function addLeaderAction(data) {
  return request({
    url: "/evaluation/addLeader",
    method: "post",
    data,
  });
}

export function getUserScore(data) {
  return request({
    url: "/evaluation/getUserScore",
    method: "post",
    data,
  });
}

export function deleteLeaderAction(data) {
  return request({
    url: "/evaluation/deleteLeader",
    method: "post",
    data,
  });
}

export function getResultSettingsAction(data) {
  return request({
    url: "/evaluation/getResultSettings",
    method: "post",
    data,
  });
}

export function getEvaluationByTypeAction(data) {
  return request({
    url: "/evaluation/getEvaluationByType",
    method: "post",
    data,
  });
}

export function saveEvaluateItemsAction(data) {
  return request({
    url: "/evaluation/saveEvaluateItems",
    method: "post",
    data,
  });
}

export function startEvaluateAction(data) {
  return request({
    url: "/evaluation/startEvaluate",
    method: "post",
    data,
  });
}

export function startEvaluateByEachAction(data) {
  return request({
    url: "/evaluation/startEvaluateByEach",
    method: "post",
    data,
  });
}

export function endEvaluateAction(data) {
  return request({
    url: "/evaluation/endEvaluate",
    method: "post",
    data,
  });
}

export function getUserScoreByUserIdAction(data) {
  return request({
    url: "/evaluation/getUserScoreByUserId",
    method: "post",
    data,
  });
}

export function getEvaluateDetailsAction(data) {
  return request({
    url: "/evaluation/getEvaluateDetails",
    method: "post",
    data,
  });
}

export function checkUserIsLeaderAction(data) {
  return request({
    url: "/evaluation/checkUserIsLeader",
    method: "post",
    data,
  });
}

export function updateHrScoreAction(data) {
  return request({
    url: "/evaluation/updateHrScore",
    method: "post",
    data,
  });
}

export function exportEvaluateAction(data) {
  return request({
    url: "/evaluation/exportEvaluate",
    method: "post",
    data,
  });
}

export function test() {
  return request({
    url: "/index/getList",
    method: "post",
  });
}

export function toggleEvaluateSelfSwitchAction(data) {
  return request({
    url: "/evaluation/toggleEvaluateSelfSwitch",
    method: "post",
    data,
  });
}

export function getScoreRangeAction(data) {
  return request({
    url: "/evaluation/getScoreRange",
    method: "post",
    data,
  });
}

export function setStarScoreAction(data) {
  return request({
    url: "/evaluation/setStarScore",
    method: "post",
    data,
  });
}

export function resetAndMessageAction(data) {
  return request({
    url: "/evaluation/resetAndMessage",
    method: "post",
    data,
  });
}

export function messageEvaluateSelfAction(data) {
  return request({
    url: "/evaluation/messageEvaluateSelf",
    method: "post",
    data,
  });
}

export function messageEvaluateEachAction(data) {
  return request({
    url: "/evaluation/messageEvaluateEach",
    method: "post",
    data,
  });
}

export function exportPersonEvaluateAction(data) {
  return request({
    url: "/exporter/exportPersonEvaluate",
    method: "post",
    data,
  });
}

export function exportAllEvaluateAction(data) {
  return request({
    url: "/exporter/exportAllEvaluate",
    method: "post",
    data,
  });
}
