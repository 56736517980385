import router from "./router";
import { getToken } from "./utils/auth"; // get token from cookie
import Axios from "axios";
const whiteList = ["/login"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // determine whether the user has logged in
  const hasToken = getToken();
  // const hasToken = store.getters.token;
  Axios.defaults.headers.common["x-admin-token"] = hasToken;
  console.log(hasToken);
  if (hasToken == "undefined" || hasToken == "") {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`);
    }
    // next(`/login?redirect=${to.path}`);
    // next({ path: "/login" });
  } else {
    next();
  }
});

router.afterEach(() => {
  // finish progress bar
  // NProgress.done();
});
