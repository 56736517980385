<template>
  <div class="home screen margin-bottom-tab padding-top">
    <div class="text-lg">{{ organizationInfo.org_name }}</div>
    <div class="flex align-center justify-between padding-sm padding-top">
      <div class="title text-lg text-bold">评价列表</div>
      <van-button type="info" size="small" @click="showDialogOne"
        >星级标准</van-button
      >
    </div>
    <van-collapse v-model="activeName" accordion>
      <!-- 使用v-for循环渲染每个年度的折叠面板 -->
      <van-collapse-item
        v-for="(yearItem, index) in yearsData"
        :key="index"
        :name="yearItem.year"
      >
        <template #title>
          <div class="flex align-center">
            <div class="point bg-blue margin-right-xs"></div>
            <div class="title text-df">{{ yearItem.year }}年度</div>
          </div>
        </template>
        <van-cell-group>
          <!-- 假设每个年份下有两个阶段的数据 -->
          <van-cell
            v-for="(stageItem, stageIndex) in yearItem.list"
            :key="stageIndex"
            :value="stageItem.statusText"
            is-link
            to="/result"
            @click="storeResult(stageItem.id, yearItem.year, stageItem.stage)"
          >
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <div class="flex justify-start align-center">
                <span class="custom-title margin-right-sm">{{
                  stageItem.stageText
                }}</span>
              </div>
            </template>
            <template #default>
              <div :class="'text-' + getStatusText(stageItem.status).textColor">
                {{ getStatusText(stageItem.status).statusText }}
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </van-collapse-item>
    </van-collapse>

    <!-- <van-button type="info" round size="normal" @click="goHome"
      >登 录</van-button
    > -->
    <!-- <div class="bottom-wrap">
      <div class="bottom"></div>
    </div> -->

    <van-dialog
      @confirm="onConfirm"
      @cancel="onCancel"
      v-model="dialog_show"
      title="编辑星级标准"
      show-cancel-button
    >
      <van-form>
        <div class="flex flex-direction padding">
          <div
            class="flex flex-direction align-center"
            v-for="(item, index) in scoreRange"
            :key="index"
          >
            <van-field
              input-align="center"
              v-model="item.score_text"
              placeholder=""
            />
            <div class="flex justify-around align-center padding-top">
              <van-stepper
                v-model="item.score_down"
                step="0.1"
                :decimal-length="1"
                min="0"
                max="5"
              />
              <div class="padding-lr">~</div>
              <van-stepper
                v-model="item.score_up"
                step="0.1"
                :decimal-length="1"
                min="0"
                max="5"
              />
            </div>
          </div>
        </div>
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import { getList } from "../api/index.js";
import { organizationInfoAction } from "../api/getCompanyInfo.js";
import { getScoreRangeAction, setStarScoreAction } from "../api/evaluation.js";
import { removeToken } from "../utils/auth";
import Vue from "vue";
import {
  Collapse,
  CollapseItem,
  Cell,
  CellGroup,
  Button,
  Dialog,
  Form,
  Field,
  Stepper,
} from "vant";
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Button);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(Field);
Vue.use(Stepper);
export default {
  name: "Home",
  components: {},
  methods: {
    showDialogOne() {
      this.dialog_show = true;
    },
    setStarScore() {},
    onCancel() {},
    onConfirm() {
      let that = this;
      let scoreRange = this.scoreRange;
      setStarScoreAction({ scoreRange: scoreRange }).then((res) => {
        let errno = res.errno;
        // let data = res.data;
        if (errno == 0) {
          that.$message({
            type: "success",
            message: "更新成功",
          });
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    goHome() {},
    groupByYear(data) {
      // 定义一个数组，用来存放最终的组织结果
      const organizedData = [];
      // 遍历数据，按照年份和阶段组织
      data.forEach((item) => {
        // 根据年份查找是否已经存在对应的年份对象
        const yearIndex = organizedData.findIndex(
          (year) => year.year === item.year
        );
        const stageText = item.stage === 0 ? "年中" : "年终";
        if (yearIndex === -1) {
          // 如果不存在，创建一个新的年份对象，并添加到结果数组中
          organizedData.push({
            year: item.year,
            list: [
              {
                id: item.id,
                stage: item.stage,
                status: item.status,
                stageText, // 阶段文本
                createTime: item.create_time,
                updateTime: item.update_time,
                isDelete: item.is_delete,
              },
            ],
          });
        } else {
          // 如果已经存在，向该年份的列表中添加新数据
          organizedData[yearIndex].list.push({
            id: item.id,
            stage: item.stage,
            status: item.status,
            stageText,
            createTime: item.create_time,
            updateTime: item.update_time,
            isDelete: item.is_delete,
          });
        }
      });
      return organizedData;
    },
    getStatusText(status) {
      let statusText = "";
      let textColor = "";
      if (status == 0) {
        statusText = "未开始";
        textColor = "gray";
      }
      if (status == 1) {
        statusText = "自评阶段";
        textColor = "red";
      }
      if (status == 2) {
        statusText = "互评阶段";
        textColor = "red";
      }
      if (status == 3) {
        statusText = "已完成";
        textColor = "green";
      }
      return {
        statusText: statusText,
        textColor: textColor,
      };
    },
    clearToken() {
      removeToken();
    },
    close() {
      this.show = false;
      this.show1 = false;
    },
    storeResult(id, year, stage) {
      localStorage.setItem("result_id", id);
      localStorage.setItem("year", year);
      localStorage.setItem("stage", stage);
    },
    getOrganizationInfo() {
      let that = this;
      organizationInfoAction().then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          that.organizationInfo = data;
          that.getScoreRange();
        } else {
          that.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    getScoreRange() {
      let result_id = this.result_id;
      let organizationInfo = this.organizationInfo;
      getScoreRangeAction({
        result_id: result_id,
        organization_id: organizationInfo.id,
      }).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          this.scoreRange = data;
        } else {
          this.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
    getList() {
      getList({}).then((res) => {
        let errno = res.errno;
        let data = res.data;
        if (errno == 0) {
          data = this.groupByYear(data);
          this.yearsData = data;
          this.activeName = data[0].year;
        } else {
          this.$message({
            type: "error",
            message: res.errmsg,
          });
        }
      });
    },
  },
  data() {
    return {
      yearsData: [],
      activeName: "",
      organizationInfo: {},
      dialog_show: false,
      scoreRange: [],
      result_id: 0,
    };
  },
  mounted() {
    let result_id = localStorage.getItem("result_id");
    this.result_id = result_id;
    this.getList();
    this.getOrganizationInfo();
  },
};
</script>

<style scoped lang="scss">
.home {
  height: 100%;
}
.rank {
  width: 8px;
  height: 16px;
}

.score-width {
  width: 20px;
}
.screen {
  max-width: 600px;
  margin: auto;
}
.bottom-wrap {
  max-width: 600px;
  height: 50px;
  position: fixed;
  bottom: 0;
  .bottom {
    max-width: 600px;
    margin: auto;
    position: relative;
  }
}
.border {
  margin-top: 8px;
  border: 1px solid #d4d4d4;
}
::v-deep .van-cell__title {
  display: flex;
  justify-content: flex-start;
}
::v-deep .van-collapse-item__content {
  padding: 0;
}
.add {
  ::v-deep .van-cell {
    padding-top: 0;
  }
}
.margin-bottom-tab {
  margin-bottom: 100px;
}
</style>